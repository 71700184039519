import React, { createContext, useContext, useState } from 'react';

const profileContext = createContext();

export function UserProfileProvider({ children }) {
  const [userProfile, setUserProfile] = useState({
    firstName: 'Avinash',
    lastName: 'Kadam',
    mobileNumber: '123-456-7890',
    email: 'avinash.kadam@prototechsolutions.com',
    gender: 'Male',
    height: 175,
    age: 25
  });

  return (
    <profileContext.Provider value={{ userProfile, setUserProfile }}>
      {children}
    </profileContext.Provider>
  );
}

export function userProfileData() {
  return useContext(profileContext);
}
