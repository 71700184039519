import { Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
import React, { useEffect, useState, useContext } from 'react';
import {
  Text,
  Image,
  View,
  Button,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  TouchableOpacity
} from 'react-native';

import { getProduct } from '../services/ProductsService.js';
import { CartContext } from '../contexts/CartContext.js';

export function ProductDetails({ route }) {
  const { productId, recomendedSize } = route.params;
  const [product, setProduct] = useState({});
  const [receivedData, setReceivedData] = useState(null); // State to store received data
  const [selectedSize, setSelectedSize] = useState(null);
  const [recommendedSize, setRecommendedSize] = useState(null); // State to store recommended size

  const { addItemToCart } = useContext(CartContext);

  useEffect(() => {
    setProduct(getProduct(productId));
  }, [productId]);

  useEffect(() => {
    if(recomendedSize)
      setRecommendedSize(recomendedSize.toLowerCase());
    setReceivedData(recomendedSize)
  }, [recomendedSize]);

  function onAddToCart() {
    addItemToCart(product.id);
  }

  // Array of available sizes
  const sizes = ['xs', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl'];

  return (
    <SafeAreaView>
      <ScrollView>
        <Image
          style={styles.image}
          source={{ uri: product.image }}
        />
        <View style={styles.infoContainer}>
          <Text style={styles.name}>{product.name}</Text>
          <Text style={styles.price}>$ {product.price}</Text>
          <Text style={styles.description}>{product.description}</Text>

          <View style={styles.sizeContainer}>
            <Text style={styles.sizeLabel}>Size:</Text>
            {sizes.map((size) => (
              <TouchableOpacity
                key={size}
                style={[
                  styles.sizeButton,
                  selectedSize === size ? styles.selectedSizeButton : {},
                  recommendedSize === size ? styles.recommendedSizeButton : {}, // Apply a special style for the recommended size
                ]}
                onPress={() => setSelectedSize(size)}
              >
                <Text style={[styles.sizeButtonText, selectedSize === size ? styles.selectedSizeText : {}, recommendedSize === size ? styles.recommendedSizeText : {}]}>{size}</Text>
              </TouchableOpacity>
            ))}
          </View>
          
          {/* Display the received data */}
          {receivedData && (
            <View >
              <Text style={styles.receivedData}>Recommended Size: {receivedData}</Text>
            </View>
          )}
          
          <View style={styles.buttonContainer}>
            {/* <Button onPress={onGetMySize} title="Get my size" /> */}
            <Button onPress={onAddToCart} title="Add to cart" />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({

  image: {
    height: 250,  // Adjust dimensions based on the screen width
    maxWidth: '100%',
  },

  infoContainer: {
    padding: 14,
  },
  name: {
    fontSize: 20,
    fontWeight: "bold",
  },
  price: {
    fontSize: 16,
    fontWeight: "600",
    marginTop: 8,
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    fontWeight: "400",
    color: "#787878",
    marginBottom: 16,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 16,
  },
  receivedData: {
    fontSize: 16,
    fontWeight: "600",
  },
  sizeContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  sizeLabel: {
    fontSize: 16,
    fontWeight: "600",
    marginRight: 10,
  },
  sizeButton: {
    paddingHorizontal: 5,
    paddingVertical: 4,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#333",
    marginRight: 10,
  },
  selectedSizeButton: {
    backgroundColor: "rgb(33, 150, 243)",
    borderColor: "rgb(33, 150, 243)",
  },
  recommendedSizeButton: {
    backgroundColor: "green",
    borderColor: "green",
  },
  selectedSizeText: {
    color: "white",
  },
  recommendedSizeText: {
    color: "white",
  },
  sizeButtonText: {
    fontSize: 13,
    fontWeight: "600",
    color: "black",
  },
});
