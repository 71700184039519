import React, { createContext, useContext, useState } from 'react';

const MeasurementDataContext = createContext();

export function MeasurementDataProvider({ children }) {
  const [measurementData, setMeasurementData] = useState(null);

  return (
    <MeasurementDataContext.Provider value={{ measurementData, setMeasurementData }}>
      {children}
    </MeasurementDataContext.Provider>
  );
}

export function useMeasurementData() {
  return useContext(MeasurementDataContext);
}
