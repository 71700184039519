import React from 'react';
import { StyleSheet } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { ProductsList } from './screens/ProductsList.js';
import { ProductDetails } from './screens/ProductDetails.js';
import { Cart } from './screens/Cart.js';
import { Profile } from './screens/Profile.js';
import { CustomHeader } from './components/Header.js'
import { CartProvider } from './contexts/CartContext.js';
import { MeasurementDataProvider } from './contexts/measurementContext.js';
import { UserProfileProvider } from './contexts/ProfileContext.js';
import { LoadingProvider } from './contexts/loadingContext.js';

const Stack = createNativeStackNavigator();

function App() {
  return (
    <LoadingProvider>
    <UserProfileProvider>
    <CartProvider>
      <MeasurementDataProvider>
      <NavigationContainer>
        <Stack.Navigator>
          <Stack.Screen name='Products' component={ProductsList} 
            options={({ navigation }) => ({
              title: 'Products',
              headerTitleStyle: styles.headerTitle,
              headerRight: () => <CustomHeader navigation={navigation}/>,
          })}/>
          <Stack.Screen name='ProductDetails' component={ProductDetails} 
            options={({ navigation }) => ({
              title: 'Details',
              headerTitleStyle: styles.headerTitle,
              headerRight: () => <CustomHeader navigation={navigation}/>,
          })} />
          <Stack.Screen name='Cart' component={Cart} 
            options={({ navigation }) => ({
              title: 'My cart',
              headerTitleStyle: styles.headerTitle,
              headerRight: () => <CustomHeader navigation={navigation}/>,
          })} />
          <Stack.Screen name='Profile' component={Profile} 
            options={({ navigation }) => ({
              title: 'Profile',
              headerTitleStyle: styles.headerTitle,
              headerRight: () => <CustomHeader navigation={navigation}/>,
          })} />
        </Stack.Navigator>
      </NavigationContainer>
      </MeasurementDataProvider>
    </CartProvider>
    </UserProfileProvider>
    </LoadingProvider>
  );
}

const styles = StyleSheet.create({
  headerTitle: {
    fontSize: 18
  }
});

export default App;
