import React, {useEffect, useState} from 'react';
import { View, Text, FlatList, StyleSheet } from 'react-native';

import { Product } from '../components/Product.js';
import { getProducts } from '../services/ProductsService.js';
import { useMeasurementData } from '../contexts/measurementContext.js';
import { getSizeFitData } from '../services/measurementService.js'

export function ProductsList ({navigation}) {
  const {measurementData} = useMeasurementData()

  async function getRecommendationForSizeFit(productId){
    var chest_measurement;
    let chest_girthID = 105;
    let bust_girthID = 144;
    if (measurementData){
      for (let i = 0; i < measurementData.length; i++) {
        if (measurementData[i].id == chest_girthID && measurementData[i].length > 0) {
          chest_measurement = measurementData[i].length;
          break;
        }
        if (filteredMeasurements[i].id == bust_girthID && measurementData[i].length > 0) {
          chest_measurement = measurementData[i].length;
          break;
        }
      }
      let size = await getSizeFitData(productId, chest_measurement)
      return size.recomendedSize;
    } else {
      return null;
    }
  }

  function renderProduct({item: product}) {
    return (
      <Product {...product} 
      onPress={async() => {
        let recomendedSize = await getRecommendationForSizeFit(product.id)
        navigation.navigate('ProductDetails', {
          productId: product.id,
          recomendedSize : recomendedSize,
        });
      }}
      />
    );
  }
  
  const [products, setProducts] = useState([]);
  
  useEffect(() => {
    setProducts(getProducts());
  });
  
  return (
    <FlatList
      style={styles.productsList}
      contentContainerStyle={styles.productsListContainer}
      keyExtractor={(item) => item.id.toString()}
      data={products}
      renderItem={renderProduct}
    />
  );
}

const styles = StyleSheet.create({
  productsList: {
    backgroundColor: '#eeeeee',
  },
  productsListContainer: {
    backgroundColor: '#eeeeee',
    paddingVertical: 8,
    marginHorizontal: 8,
  },
});
