import React, { useState } from 'react';
import { View, ActivityIndicator, Image  } from 'react-native';
import { MyProfile } from './MyProfile.js';
import { CartIcon } from './CartIcon.js';
import { useLoading } from '../contexts/loadingContext.js';


export function CustomHeader({navigation}) {
    const {isLoading} = useLoading();

    return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {isLoading ? (
                <ActivityIndicator size={30} color="orange" style={{marginRight: '12px'}} />
            ) : null} 
            <CartIcon navigation={navigation} />
            <MyProfile navigation={navigation}/>
        </View>
    );
}