
import React, { useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';

export function MyProfile({navigation}) {
    return (
        <View style={styles.profileContainer}>
        <Text style={styles.text} 
            onPress={() => {
            navigation.navigate('Profile');
            }}
        >Profile</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    profileContainer: {
        marginHorizontal: 8,
        backgroundColor: 'orange',
        height: 32,
        padding: 12,
        borderRadius: 32 / 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        color: 'white',
        fontWeight: 'bold',
    },
});
