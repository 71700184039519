import axios from 'axios';

// Function to fetch data from the getsavedmodels API endpoint
export const getSavedMeasurements = (reqId) => {
    const url = `https://image2scan.3dmeasureup.com/getmeasurement?requestId=${reqId}`
    return axios.get(url, {
        headers: {
            "Content-type": "application/json"
        }
    })
    .then(response => {
        if (response.status === 200) {
            return response.data; // Resolve with the measurements
        } else if (response.status === 202) {
            // Retry after 5 seconds
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(getSavedMeasurements(reqId));
                }, 5000);
            });
        } else {
            console.error('Error fetching data:', response);
            throw new Error('Error fetching data');
        }
    })
    .catch(error => {
        console.error('Error fetching data:', error);
        throw error;
    });
};


// Function to fetch data from the getsizefit API endpoint
export const getSizeFitData = (productId, chest_measurement) => {
    const url = `https://image2scan.3dmeasureup.com/getsizefit`
    const body = {
        productId: productId,
        targetSize: chest_measurement,
        fitType: "tight_fit",
      };
    return axios.post(url, body, {
        headers: {
            "Content-type": "application/json"
        }
    })
    .then(response => {
        if (response.status === 200) {
            // return response.data; // Resolve with the measurements
            return new Promise(resolve => {resolve(response.data)});
        } else {
            console.error('Error fetching data:', response);
            // show error
        }
    })
    .catch(error => {
        console.error('Error fetching data:', error);
        // show error;
    });
};