import React from 'react';
import { Text, Image, View, StyleSheet, TouchableOpacity } from 'react-native';

export function Product({ name, price, image, onPress }) {
  return (
    <div style={styles.productContainer}>
      <TouchableOpacity style={styles.card} onPress={onPress}>
        <div className="image-container">
          <img
            style={styles.thumb}
            src={image}
            alt={name}
          />
        </div>
        <View style={styles.infoContainer}>
          <Text style={styles.name}>{name}</Text>
          <Text style={styles.price}>$ {price}</Text>
        </View>
      </TouchableOpacity>
    </div>
  );
}

const styles = StyleSheet.create({
  productContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 16,
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.2)',
    width: '350px',
    margin: '10px',
  },
  thumb: {
    maxWidth: '100%',
    height: 'auto',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  infoContainer: {
    padding: 16,
  },
  name: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  price: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 8,
  },
});
