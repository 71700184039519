const PRODUCTS = [
    {
        id: 8519871,
        name: "Men's Running T-shirt Run Dry - Prussian Blue",
        price: 150,
        image: "https://contents.mediadecathlon.com/p1613531/71a29a5c4bb7b35d3ce9924cdbdebb51/p1613531.jpg?format=auto&quality=70&f=650x0",
        description: 'A headset combines a headphone with microphone. Headsets are made with either a single-earpiece (mono) or a double-earpiece (mono to both ears or stereo).'
    },
    {
        id: 8771124,
        name: "Men's Running T Shirt - Red",
        price: 155,
        image: "https://contents.mediadecathlon.com/p2397138/128e3f6654e5f6c37e12e576df14e043/p2397138.jpg?format=auto&quality=70&f=650x0",
        description: 'A model car, or toy car, is a miniature representation of an automobile. Other miniature motor vehicles, such as trucks, buses, or even ATVs, etc. are often included in this general category.'
    },
    {
        id: 8388645,
        name: "Men's Cotton Gym T-shirt Regular fit Sportee - White",
        price: 250,
        image: "https://contents.mediadecathlon.com/p2030012/99aeb60164adf564e1f5b729eefc8474/p2030012.jpg?format=auto&quality=70&f=650x0",
        description: 'A cupcake (also British English: fairy cake; Hiberno-English: bun; Australian English: fairy cake or patty cake[1]) is a small cake designed to serve one person.'
    },
    {
        id: 8588418,
        name: "Men's Cotton Gym T-Shirt Regular Fit Athletee 100 - Dark Grey",
        price: 150,
        image: "https://contents.mediadecathlon.com/p2007630/3af421510ba80a870940528489ea8bd7/p2007630.jpg?format=auto&quality=70&f=650x0",
        description: 'A headset combines a headphone with microphone. Headsets are made with either a single-earpiece (mono) or a double-earpiece (mono to both ears or stereo).'
    },
    {
        id: 8628474,
        name: "Men Golf Polo T-shirt 500 sepia",
        price: 155,
        image: "https://contents.mediadecathlon.com/p1987841/dfaa654a7b921008235a84de288b5deb/p1987841.jpg?format=auto&quality=70&f=650x0",
        description: 'A model car, or toy car, is a miniature representation of an automobile. Other miniature motor vehicles, such as trucks, buses, or even ATVs, etc. are often included in this general category.'
    },
    {
        id: 8732658,
        name: "Men's golf short-sleeved polo T-shirt MW500 sky blue",
        price: 250,
        image: "https://contents.mediadecathlon.com/p2238855/40edc4c134b0900b7e1289b17fe158a3/p2238855.jpg?format=auto&quality=70&f=650x0",
        description: 'A cupcake (also British English: fairy cake; Hiberno-English: bun; Australian English: fairy cake or patty cake[1]) is a small cake designed to serve one person.'
    },
    {
        id: 8406669,
        name: "Men Football Jersey shirt FIOO - Black",
        price: 155,
        image: "https://contents.mediadecathlon.com/p1604462/0fc1067528aec0a553f8b2577eab0ec1/p1604462.jpg?format=auto&quality=70&f=650x0",
        description: 'A model car, or toy car, is a miniature representation of an automobile. Other miniature motor vehicles, such as trucks, buses, or even ATVs, etc. are often included in this general category.'
    },
    {
        id: 8406670,
        name: "Men Football Jersey shirt FICO - Red",
        price: 250,
        image: "https://contents.mediadecathlon.com/p1604468/73a26a4a57d1c23792bf6dd7110ecaba/p1604468.jpg?format=auto&quality=70&f=650x0",
        description: 'A cupcake (also British English: fairy cake; Hiberno-English: bun; Australian English: fairy cake or patty cake[1]) is a small cake designed to serve one person.'
    }

];

export function getProducts() {
    return PRODUCTS;
}

export function getProduct(id) {
    return PRODUCTS.find((product) => (product.id == id));
}

