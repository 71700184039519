import React, { useEffect, useState, useContext } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput, Button } from 'react-native';
import {getSavedMeasurements} from '../services/measurementService';
import { useMeasurementData } from '../contexts/measurementContext';
import { userProfileData } from '../contexts/ProfileContext';
import { useLoading } from '../contexts/loadingContext';

export function Profile() {
  // Replace these with your actual user data
  const {userProfile, setUserProfile} = userProfileData();
  const [receivedData, setReceivedData] = useState(null); // State to store received data
  const {setMeasurementData} = useMeasurementData(); // State to store received data
  const { toggleLoading  } = useLoading();

  function launchPhoto2Fit3DMU() {
    // Open the external website in a new window and store the reference
    window.open(`https://me.3dmeasureup.ai?height=${userProfile.height}`, '_blank');
    // window.open(`http://172.16.16.76:8598`, '_blank');
  }

  // Add an event listener to receive data from the child window inside a useEffect
  useEffect(() => {
    window.addEventListener('message', function (event) {
      if (event.origin === 'https://me.3dmeasureup.ai') {
        const data = event.data;
        receiveDataFromChild(data);
      }
    });

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('message', receiveDataFromChild);
    };
  }, []); // Empty dependency array ensures that this effect runs once after mounting


  // Function to receive data from the redirected page
  async function receiveDataFromChild(data) {
    // Update the state with the received data
    toggleLoading(true);
    setMeasurementData(null);
    setReceivedData(data);
    // Get Measurements from the srver
    var respData = await getSavedMeasurements(data);
    if(respData.status_code == 200){
      setMeasurementData(respData.response);
      toggleLoading(false);
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.profileSection}>
        <Text style={styles.label}>First Name : </Text>
        {/* <Text style={styles.value}>{user.firstName}</Text> */}
        <TextInput style={styles.value} value={userProfile.firstName} onChangeText={(text) => setUserProfile({ ...userProfile, firstName: text })}/>
      </View>
      <View style={styles.profileSection}>
        <Text style={styles.label}>Last Name : </Text>
        <TextInput style={styles.value} value={userProfile.lastName} onChangeText={(text) => setUserProfile({ ...userProfile, lastName: text })}/>
      </View>
      <View style={styles.profileSection}>
        <Text style={styles.label}>Mobile Number : </Text>
        <TextInput style={styles.value} value={userProfile.mobileNumber} onChangeText={(text) => setUserProfile({ ...userProfile, mobileNumber: text })}/>
      </View>
      <View style={styles.profileSection}>
        <Text style={styles.label}>Email : </Text>
        <TextInput style={styles.value} value={userProfile.email} onChangeText={(text) => setUserProfile({ ...userProfile, email: text })}/>
      </View>
      <View style={styles.profileSection}>
        <Text style={styles.label}>Gender : </Text>
        <TextInput style={styles.value} value={userProfile.gender} onChangeText={(text) => setUserProfile({ ...userProfile, gender: text })}/>
      </View>
      <View style={styles.profileSection}>
        <Text style={styles.label}>Age : </Text>
        <TextInput style={styles.value} value={userProfile.age} onChangeText={(text) => setUserProfile({ ...userProfile, age: text })}/>
      </View>
      <View style={styles.profileSection}>
        <Text style={styles.label}>Height : </Text>
        <TextInput style={styles.value} value={userProfile.height} onChangeText={(text) => setUserProfile({ ...userProfile, height: text })}/>
      </View>
      <View style={styles.sizeContainer}>
        <Text style={styles.value}>Size Recomendation :  </Text>
        <View style={styles.buttonContainer}>
          <Button
            onPress={launchPhoto2Fit3DMU}
            title={
              <Text style={styles.buttonText}>Upload size pic</Text>
            }
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#eeeeee',
    padding: 16,
  },
  profileSection: {
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    marginBottom: 16,
  },
  label: {
    fontSize: 14,
    color: '#333333',
    marginBottom:5
  },
  value: {
    fontSize: 18,
    color: '#333333',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  sizeContainer: {
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 16,  
  },
  buttonContainer: {
    borderRadius: 25,
    overflow: 'hidden',
    width: 'auto',
  },
  buttonText: {
    fontWeight: 'bold', // Make the button text bold
    marginHorizontal: 8,
  },
});

export default Profile;
